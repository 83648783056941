import { Injectable, inject } from '@angular/core';

import { MODULE_INFOS_TOKEN } from '../injection-tokens';
import { ModuleInfo } from '../models';

@Injectable({ providedIn: 'root' })
export class ModuleInfoService {
  private readonly registeredModuleInfos: readonly ModuleInfo[] = inject(MODULE_INFOS_TOKEN);

  private readonly moduleInfosByName: Record<string, ModuleInfo> = this.buildModuleInfoMap();

  /**
   * Returns the display name of the given module. If not provided, the module name is returned.
   */
  getDisplayName(moduleName: string): string {
    return this.moduleInfosByName[moduleName]?.displayName || moduleName;
  }

  private buildModuleInfoMap(): Record<string, ModuleInfo> {
    return this.registeredModuleInfos.reduce((result, moduleInfo) => {
      return {
        ...result,
        [moduleInfo.name]: moduleInfo,
      };
    }, {});
  }
}
